//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import dayjs from "dayjs";
import TaskCalendarItemCardJobWidget from "./ItemCardJob";

export default {
  name: "TaskCalendarDesktopCellWidget",
  components: {
    TaskCalendarItemCardJobWidget,
  },
  props: {
    data: {
      type: Object,
    },
    day: {
      type: Date,
    },
    week: {
      type: String,
    },
  },
  filters: {
    dateFormat(day) {
      return dayjs(day).format("MM-DD");
    },
    weekFormat(day) {
      const week = day.getDay();
      let result;
      switch (week) {
        case 0:
          result = "日";
          break;
        case 1:
          result = "一";
          break;
        case 2:
          result = "二";
          break;
        case 3:
          result = "三";
          break;
        case 4:
          result = "四";
          break;
        case 5:
          result = "五";
          break;
        case 6:
          result = "六";
          break;
      }
      return `周${result}`;
    },
  },
};
