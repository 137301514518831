//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "TaskCalendarItemCardJobWidget",
  props: {
    data: {
      type: Object,
      default: {},
    },
    onlyOneLine: {
      type: Boolean,
      default: false,
    },
    popover: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    schedule() {
      return this.data.Schedule;
    },
    name() {
      return this.data.Name;
    },
    time() {
      return this.data.Time;
    },
    totalTime() {
      return this.data.TotalTime;
    },
    progress() {
      return this.data.Progress;
    },
    color() {
      // ?? ?. ??= [doge]
      return (
        this.data.color || {
          color: "inherit",
        }
      );
    },
  },
};
