//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import dayjs from "dayjs";
import color from "color";
import randomColor from "randomcolor";
import TaskCalendarDesktopCellWidget from "./DesktopCell";
import TaskCalendarItemCardJobWidget from "./ItemCardJob";

export default {
  name: "TaskCalendarWidget",
  components: {
    TaskDetail: () => import("@/userview/task-manager/task-modal"),
    TaskCalendarDesktopCellWidget,
    TaskCalendarItemCardJobWidget,
  },
  props: {
    list: {
      // interface item {
      //   date: String,
      //   jobs: <job>[],
      // }
      // interface job {
      //   name: String,
      //   jId: int,
      //   eId: int,
      //   time: double, // hour
      //   schedule: 0：正在执行，1：即将逾期，2：已逾期,
      //   progress: int, int / 100 进度
      //   totalTime: double, // hour
      // }
      type: Array, // <item>[]
      default: [],
    },
    desktopStickyTop: {
      type: String,
      default: "0",
    },
    mobileStickyTop: {
      type: String,
      default: "0",
    },
    teamValue: {
      type: Number,
      default: 0,
    },
    taskDetailPrefix: {
      type: String,
      default: "",
      require: true,
    },
  },
  data: () => ({
    jId: null,
    taskDetailName: "taskDetailCalandar",
    detailUrl: null,
    detailReferer: null,
    indexData: {
      type: "", // Add Edit
      name: "",
      xModalName: "",
    },
    colors: [
      "#ff7875",
      "#f5222d",
      "#fa541c",
      "#ad2102",
      "#ad4e00",
      "#08979c",
      "#69c0ff",
      "#1890ff",
      "#597ef7",
      "#b37feb",
      "#722ed1",
      "#ff85c0",
      "#c41d7f",
    ],
  }),
  computed: {
    data() {
      if (this.list.length < 0) return [];

      const result = [];
      this.list.forEach((e) => {
        const { date, jobs } = e;

        const day = dayjs(date).format("YYYY-MM");
        const has = !!result.find(({ date }) => date === day);
        if (has) {
          const idx = result.findIndex(({ date }) => date === day);
          result[idx].list.push(e);
        } else {
          result.push({
            date: day,
            list: [e],
          });
        }
      });

      const jIds = [];
      result.forEach(({ list }, a) => {
        list.forEach(({ jobs }, b) => {
          jobs.forEach(({ JId }, c) => {
            jIds.push(JId);
          });
        });
      });
      const jIdsCount = [];
      jIds.forEach((i) => {
        const find = jIdsCount.find(({ id }) => id === i);
        const has = !!find;
        if (has) {
          const idx = jIdsCount.findIndex(({ id }) => id === i);
          jIdsCount[idx].count += 1;
        } else {
          jIdsCount.push({
            id: i,
            count: 1,
          });
        }
      });
      const filterJIds = [];
      jIdsCount.forEach(({ id, count }) => {
        if (count > 1)
          filterJIds.push({
            id,
            color:
              this.colors[this.getRandomArbitrary(0, this.colors.length - 1)],
          });
      });
      // console.log(filterJIds, jIdsCount)
      // const filterJIds = [...new Set(jIds)]
      // console.log(filterJIds.length, jIds.length)

      result.forEach(({ list }, a) => {
        list.forEach(({ jobs }, b) => {
          jobs.forEach(({ JId }, c) => {
            filterJIds.forEach(({ id, color }) => {
              if (id === JId) {
                result[a].list[b].jobs[c].color = { color };
              }
            });
          });
        });
      });

      return result;
    },
  },
  filters: {
    dateFormat(day) {
      return dayjs(day).format("YYYY-MM-DD");
    },
    weekFormat(_) {
      const day = new Date(_);
      const week = day.getDay();
      let result;
      switch (week) {
        case 0:
          result = "日";
          break;
        case 1:
          result = "一";
          break;
        case 2:
          result = "二";
          break;
        case 3:
          result = "三";
          break;
        case 4:
          result = "四";
          break;
        case 5:
          result = "五";
          break;
        case 6:
          result = "六";
          break;
      }
      return `周${result}`;
    },
  },
  methods: {
    xmodalloadSuccess() {
      if (this.teamValue && this.jId) {
        this.$modal.show(this.taskDetailName);
      }
    },
    xmodalbeforeOpen() {
      var self = this;
      if (this.teamValue && this.jId) {
        this.$http
          .post("/Task/GetTaskDetail.ashx", {
            teamId: JSON.parse(this.teamValue),
            Id: JSON.parse(this.jId),
          })
          .then((resp) => {
            if (resp.res == 0) {
              self.indexData = {
                type: `${self.isMember ? "" : "Edit"}`,
                name: `${self.isMember ? "任务详情" : "编辑任务"}`,
                departmentCode: self.jId,
                row: resp.data,
                xModalName: this.taskDetailName,
              };
              self.$nextTick(() => {
                self.$refs.taskM.changeEditState();
              });
            }
          });
      }
    },
    showDetail({ jId }) {
      this.jId = jId;
      this.detailUrl = `${this.taskDetailPrefix}/${jId}`;
      this.detailReferer = this.taskDetailPrefix;
      this.$modal.show(this.taskDetailName);
    },
    getUID() {
      // 获取唯一值
      return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
        /[xy]/g,
        function (c) {
          const r = (Math.random() * 16) | 0,
            v = c == "x" ? r : (r & 0x3) | 0x8;
          return v.toString(16);
        }
      );
    },
    getRandomArbitrary(min, max) {
      return Number.parseInt(Math.random() * (max - min) + min);
    },
  },
};
